/* eslint-disable @typescript-eslint/indent */
/**
 * index.d.ts
 *
 * @author fukui
 */

import { APP_MODE_VALUE } from '@falla/config/src/config';

export interface DSBridge {
  call: ((handlerName: string, args?: any, responseCallback?: (retValue: any) => void) => any) &
    (<T, R>(handlerName: string, args?: T, responseCallback?: (retValue: R) => void) => R);

  register: ((handlerName: string, handler: object | (() => any), async?: boolean) => void) &
    (<F>(handlerName: string, handler: F, async?: boolean) => void);

  registerAsyn: ((handlerName: string, handler: object | (() => void)) => void) &
    (<F>(handlerName: string, handler: F) => void);

  hasNativeMethod: (handlerName: string, type?: 'all' | 'asyn' | 'syn') => boolean;

  disableJavascriptDialogBlock: (disable?: boolean) => void;
}

export enum WALLET_PAGES {
  coin = 0,
  gem = 1,
}

export type GotoWalletParams = WALLET_PAGES | { moduleName: string; index: WALLET_PAGES };

export interface UserDeviceInfo {
  version: string; // App版本，如 1.5
  systemVersion: string; // 系统版本，如 安卓8,9 ios14
  versionCode: number; // 安卓专用 App版本，如20 31
  deviceModel: string; // 设备类型 如： M2006J10C，iphone 8
  language: string; // 系统语言：zh-CN,ar,en,tr
  os: string; // ios android
  platform: number; // 1、app store  2、Google Play 3、apkpure.com
  mac: string; // Mac地址 9e3e18089cb5613a
  memory: number; // 仅支持安卓 可用RAM
  memoryTotal: number; // 仅支持安卓 机身总共RAM
  realVersion: string; // 5.7.3
  appTag: number; // 1: Falla; 2: Folo; 3: Falla lite
  systemCountryCode: string; // 限制app访问，举例：CN/CHN，获取不到直接传空字符串
}

export const enum AppTag {
  Falla = 0,
  Folo = 2,
  FallaLite = 3,
  Yigo = 4,
  Arhbo = 5,
  YiChat = 7,
  JoyMi = 6,
}

enum Gender {
  unkonw,
  male,
  Female,
}

export interface UserInfo {
  // 用户uid
  uid: number;
  // 绑定的靓号
  duid: number;
  // 新靓号
  suid: string;
  // 新靓号等级
  suidLv: number;

  // 昵称
  nickname: string;
  // 头像
  avatarurl: string;
  // 性别 0未知  1男 2女
  gender: Gender;
  // 财富值等级
  wealthlv: number;
  // 魅力值等级
  charmlv: number;
  // 人工设置是否靓号
  isduid: number;
  //
  headid: number;
  // 座驾id
  horseid: number;
  // 贵族勋章
  nobilityIcon: string;
  // 管理员图标
  managerIcon: string[];
  // 勋章图标
  medalsIcon: string[];
  // 1 新用户 2 非新用户
  newerStatus: number;
  // 登陆token
  accesstoken: string;
  // 融云ID
  rongcloudtoken: string;

  // 手机号码  不带国家电话编号
  mobilephone: string;
  // 电话号码所在国家区号
  area: number;
  // 用户所属国家对应的国家编号，这个id也是电话编号
  countryid: number;
  // 绑定的email
  email: string;
  // facebook的id，注意是字符串
  facebookid: string;
  // 苹果token
  appletoken: string;
  // 贵族
  nobility: number;
  // 个性签名
  signature: string;
  // 生日
  birthday: string;
  // 年龄
  age: number;
  // 兴趣
  interest: string;
  // 1普通会员  1以上有各种权限, 3 国家管理员 7超级管理员
  power: number;
  // 管理国家
  mgrCountries: number[];
  // 是否没有补充资料 == 1 需要补充
  first: number;

  countryname: string;
  // 是否是新用户
  isNew: boolean;
  // 账户注册类型
  accountType: number;
  // 用户国家区域 'AR' | 'TR' | 'EN';
  region: Regions;
  userSig: string;
}

export enum Regions {
  AR = 'AR',
  TR = 'TR',
  EN = 'EN',
  ES = 'ES',
  SA = 'SA',
}

export interface ChooseImageParams {
  count: number;
  sizeType: 'original' | 'compressed';
  // 开启mp4选择
  mp4Enabled?: boolean;
  // 限制视频长度，单位秒，不传默认值60s
  duration?: number;
}

export interface Image {
  path: string;
  imageData: string;
  size: number;
  width: number;
  height: number;
}

export interface ChooseImageResult {
  status: number;
  data: ChooseImageData;
}

export interface ChooseImageData {
  list: Image[];
}

export interface UploadFileResult {
  list: UploadListItem[];
}

export interface UploadListItem {
  filePath: string;
  url: string;
}

export interface UploadFileParams {
  filePath: string;
  fileType: 'image' | 'audio' | 'video';
}

export interface SetShareParams {
  // 分享的标题
  title?: string;
  // 分享的图片
  imageUrl?: string;
  // 分享的h5网址
  link?: string;
  // 是否显示分享按钮
  // true显示按钮 false不显示
  show?: boolean;
  // 1-H5 2-图片
  stype: 1 | 2 | number;
  // 图片宽度
  width?: number;
  // 图片高度
  height?: number;
}

export interface GotoRoomWithGame {
  gid: number; // 房间id
  gameId: 1 | number; // 固定传1
  type: 1 | 2; // 1加入(跳转并打开游戏面板) 2查看
}

export interface CpGift {
  coins: number;
}

export enum TextButtonIconTypes {
  // 标题栏菜单...更多icon
  headerBarMenu = 'HeaderBarMenu',
  none = '',
}

export interface SimpleRoom {
  owner: number;
  gid: number;
  dgid: number;
  sgid: string;
  sgidLv: number;
  name: string;
  logourl: number;
}

export interface User {
  uid: number;
  duid: number;
  suid: string;
  suidLv: number;
  nickname: string;
  avatarurl: string;
}

export interface CpLevelDialogParams {
  user: User;
  toUser: User;
  lv: number;
}

export interface ChooseUserParams {
  // 选择用户的来源
  target: 'room' | 'friend';
  // 单选、复选、支持单选和复选切换
  mode: 'radio' | 'checkBox' | 'radioAndCheckBox';
  // 选中上限人数，仅checkBox、radioAndCheckBox模式有效
  checkMinLimit?: number;
  // 选中上限人数，仅checkBox、radioAndCheckBox模式有效
  checkMaxLimit?: number;
  // 仅显示在线用户，为false在线离线都展示（target 为 friend 时忽略）
  onlyOnlineUser?: boolean;
}

export interface AppApiRes<T> {
  status: number;
  data: T;
}

export interface ChooseUserResult {
  list: User[];
}

export enum CpStatus {
  // 解绑成功
  unbindSuccess = 0,
  // 创建成功
  createSuccess = 1,
  // 赠送 cp 信物成功
  inviteSuccess = 2,
}
const protocolPrefix = 'boli://';

export enum APP_SCHEMES {
  // 加入房间
  joinRoom = `${protocolPrefix}room/join?gid={gid}`,
  // 钱包：index (0 钱包页面 1 钻石页面)
  wallet = `${protocolPrefix}wallet/recharge?index={index}&moduleName={moduleName}`,
  // 官方公告
  announce = `${protocolPrefix}announce`,
  // 系统消息
  message = `${protocolPrefix}message`,
  // 充值明细
  rechargeDetail = `${protocolPrefix}wallet/recharge_detail`,
  // 金币明细
  coinDetail = `${protocolPrefix}wallet/coin_detail`,
  // 钻石明细
  diamondDetail = `${protocolPrefix}wallet/diamond_detail`,
  // 跳转贵族卡 id (0 - 4,对应贵族 1 - 5)
  nobleCard = `${protocolPrefix}noble/card?id={id}`,
  // 勋章商城 index
  medalStore = `${protocolPrefix}medal/store?index={index}`,
  // 个人道具: index （0 我的头饰 1 我的座驾 个人道具界面）
  mineProp = `${protocolPrefix}mine/prop?index={index}`,
  // 房间背景
  roomTheme = `${protocolPrefix}room/theme`,
  // 活动详情页面: groupActivityId 活动ID
  roomActivityDetail = `${protocolPrefix}room/activity/detail?groupActivityId={groupActivityId}`,
  // url链接
  url = '{url}',
  // 跳转主题房间
  themeRoom = `${protocolPrefix}theme/room?id={id}`,
  // App头饰座驾商城：index (0 头饰 1 座驾 2 资料卡片 3 CP 4 气泡)
  propStore = `${protocolPrefix}store/prop_store?index={index}`,
  // index（跳转到用户首页 0 房间 1 消息 2 我的 3 朋友圈 4 游戏大厅）
  main = `${protocolPrefix}main?index={index}`,
  // type=3 一键匹配Ludo逻辑
  ludoMatch = `${protocolPrefix}app/ludo_match?type={type}`,
  // 投票游戏
  voteGame = `${protocolPrefix}room/vote?gid={gid}`,
  // 勋章详情
  medalDetail = `${protocolPrefix}medal/detail?medalKey={medalKey}`,
  // 用户首次充值
  userFistRecharge = `${protocolPrefix}wallet/first/alert/reward`,
  // 家族中心
  familyCenter = `${protocolPrefix}family/center?familyId={familyId}`,
  // 家族申请列表
  familyApply = `${protocolPrefix}family/apply?familyId={familyId}`,
  // 个人中心
  userCenter = `${protocolPrefix}user_center?uid={uid}`,
  // 私聊页面
  privateChat = `${protocolPrefix}private/chat?uid={uid}&nickname={name}`,
  // 家族成员列表页面
  familyMembers = `${protocolPrefix}family/members?familyId={familyId}&familyPower={familyPower}`,
  // 家族排行榜 0 日榜 1 周榜 2 月榜
  familyRank = `${protocolPrefix}family/rank?type={type}`,
  // 好友申请列表
  userFriendApply = `${protocolPrefix}user/friend_apply?uid={uid}`,
  // 动态详情
  dynamicDetail = `${protocolPrefix}moment/detail?trendId={trendId}`,
  // 话题详情
  topicDetail = `${protocolPrefix}topic/detail?topicId={topicId}`,
  // 公会中心
  guildCenter = `${protocolPrefix}guild/main?guildId={guildId}`,
  // 半屏游戏跳转
  gameDetail = `${protocolPrefix}room/game?gamePanelId={gameId}`,
}

export enum MINE_PROP_INDEX {
  headdress = 0,
  car = 1,
}

export enum GiftDialogIndex {
  gift = 0,
  bag = 1,
}

export interface StorageMapping {
  // 缓存key
  key: string;
  // 缓存value
  value: string;
  // 失效时间时间戳（13位长度），为0则永久有效
  expireTime: number;
}

export enum ExtDataBizType {
  luckyBoxAgreeOpenBox = 1,
  luckyBoxBoxOpened = 2,
  // 幸运盒子状态变化（主持人操作盒子的状态，导致活动发生变化了，当前在活动中的用户界面要发生变化）
  luckyBoxStatusChange = 3,
}

/**
 * 半屏消息: 100_022
 * 由服务端->客户端->通知前端
 * https://docs.qq.com/sheet/DZWhRR3lCbkVya2JU?tab=ddbmp2&_t=1627368067238
 */
export interface PopupWebviewExtData<T> {
  // 盒子：bizType：1-打开盒子确认弹窗，2-打开盒子
  bizType: ExtDataBizType;
  // 用于业务去重的id
  bizId: number;
  uid: number;
  gid: number;
  // 数据
  data: T;
}

export interface ShareWithFamilyInfoParams {
  familyId: number;
  name?: string;
  nameplate?: string;
  notice?: string;
  icon?: string;
  sfid: string;
  sfidLv: number;
}

export interface ApiEnvRes {
  // env: 'sz' | 'hk' | 'pre' | 'prod' | string;
  // 枚举值  sz、hk、pre、prod
  env: APP_MODE_VALUE;
  host: string;
}

export interface PlayAnimationUrlParams {
  url: string;
}

declare global {
  interface Window {
    _appBridge_: any;
  }
}

/**
 * SYSTEM_NOTIFY 表示系统通知
 * SYSTEM_GUILD_MSG 表示机构通知
 */
export enum InnerNoticeType {
  SYSTEM_GUILD_MSG = 'SYSTEM_GUILD_MSG',
  SYSTEM_NOTIFY = 'SYSTEM_NOTIFY',
}
