import { useUnmount } from 'ahooks';
import isBrowser from 'ahooks/lib/utils/isBrowser';
import { useEffect, useRef } from 'react';
import { useActivate } from 'react-activation';

export interface Options {
  restoreOnUnmount?: boolean;
}

const DEFAULT_OPTIONS: Options = {
  restoreOnUnmount: false,
};

function useDocumentTitle(title: string, options: Options = DEFAULT_OPTIONS) {
  const titleRef = useRef(isBrowser ? document.title : 'ㅤㅤ');
  useEffect(() => {
    document.title = title || 'ㅤㅤ';
  }, [title]);

  useActivate(() => {
    document.title = title || 'ㅤㅤ';
  });

  useUnmount(() => {
    if (options.restoreOnUnmount) {
      document.title = titleRef.current;
    }
  });
}

export default useDocumentTitle;
